import React, { ReactNode } from "react";
import clsx from "clsx";

export type FontColors =
  | "neutral-100"
  | "neutral-200"
  | "neutral-300"
  | "neutral-300-darker"
  | "neutral-400"
  | "neutral-500"
  | "canvas-100"
  | "canvas-200"
  | "canvas-300"
  | "canvas-400"
  | "canvas-500"
  | "brand-green-100"
  | "brand-green-200"
  | "brand-green-300"
  | "brand-green-400"
  | "brand-green-500"
  | "brand-orange-100"
  | "brand-orange-200"
  | "brand-orange-300"
  | "brand-orange-400"
  | "brand-orange-500";

export type HeadingFontSizes = "lg" | "md" | "sm" | "xs" | "xl";
export type BodyFontSizes = "sub" | "normal" | "xs";

export type TypographyProps = {
  component?: keyof JSX.IntrinsicElements;
  size?: HeadingFontSizes | BodyFontSizes;
  id?: string;
  alt?: boolean;
  className?: string;
  children: ReactNode;
  color?: FontColors | string;
  italic?: boolean;
};

export function HeadingTypography({
  component: Component = "h2",
  size = "md",
  alt,
  className,
  children,
  color = "neutral-500",
  italic,
  id,
}: TypographyProps & { size?: HeadingFontSizes }) {
  const classes = clsx({
    "text-heading-lg font-aileron-semi-bold": size === "lg" && !alt,
    "text-heading-md font-aileron-semi-bold": size === "md" && !alt,
    "text-heading-sm font-aileron-bold": size === "sm" && !alt,
    "text-heading-xs font-aileron-semi-bold": size === "xs" && !alt,
    "text-heading-alt-xl font-besley": size === "xl" && alt,
    "text-heading-alt-xl font-aileron-semi-bold": size === "xl" && !alt,
    "text-heading-alt-lg font-besley": size === "lg" && alt,
    "text-heading-alt-md font-besley-semi-bold": size === "md" && alt,
    "text-heading-alt-sm font-besley-medium": size === "sm" && alt,
    "text-heading-alt-xs font-besley-medium": size === "xs" && alt,
    "italic font-aileron-italic": italic && !alt,
    "italic font-besley-italic": italic && alt,
    [className!]: className,
    [`text-${color}`]: color,
  });

  return <Component id={id} className={classes}>{children}</Component>;
}

export function BodyTypography({
  component: Component = "p",
  size = "normal",
  className,
  italic,
  alt,
  color = "neutral-500",
  children,
  id,
}: TypographyProps & { size?: BodyFontSizes }) {
  const classes = clsx({
    "text-body-normal font-aileron": size === "normal" && !alt,
    "text-body-sub font-aileron": size === "sub" && !alt,
    "text-body-xs font-aileron-semi-bold": size === "xs" && !alt,
    "text-body-normal font-besley": size === "normal" && alt,
    "text-body-sub font-besley": size === "sub" && alt,
    "text-body-xs font-besley-medium": size === "xs" && alt,
    "italic font-aileron-italic": italic && !alt,
    "italic font-besley-italic": italic && alt,
    [`text-${color}`]: color,
    [className!]: className,
  });

  return <Component id={id} className={classes}>{children}</Component>;
}

const Typography = { Body: BodyTypography, Heading: HeadingTypography };

export default Typography;
