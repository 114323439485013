import { useMutation, UseMutationResult } from "react-query";

import { UserForest } from "../types/user.type";
import { authenticatedApi } from "../utils/api";
import { useForestStore } from "./useForestStore";

export type SelectForestResponse = {
  code: string;
  forest: UserForest;
};

export const useSelectForest = (): UseMutationResult<
  SelectForestResponse,
  Error,
  { forestId: string }
> & {
  setForest: (forest: UserForest | null) => void;
  setForestCode: (code: string | null) => void;
} => {
  const { setForest, setForestCode } = useForestStore();

  const mutation = useMutation<
    SelectForestResponse,
    Error,
    { forestId: string }
  >(
    async ({ forestId }) => {
      const response = await authenticatedApi.post<SelectForestResponse>(
        "/user/select-forest",
        { forestId },
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setForest(data.forest);
        setForestCode(data.code);
      },
      onError: () => {
        setForest(null);
        setForestCode(null);
      },
    },
  );

  return {
    ...mutation,
    setForest,
    setForestCode,
  };
};
