import clsx from "clsx";
import React from "react";

export type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  variant?: "transparent" | "primary" | "outline" | "secondary";
  colorScheme?: "default" | "black" | "green" | "gray" | "white";
  className?: string;
  style?: React.CSSProperties;
  type?: "button" | "submit" | "reset";
  icon?: React.ReactElement;
  rounded?: boolean;
};

const baseClasses =
  "px-4 py-2 flex flex-row items-center text-body-sub font-aileron transition-colors duration-200 focus:outline-none active:outline-none focus:ring-2 focus:ring-brand-green-300 focus-ring-offset-2";

const colorSchemes: Record<string, string> = {
  default: "border border-brand-blue-300 bg-brand-blue-300",
  black: "border border-black bg-gray-800",
  green: "border border-brand-green-300 bg-brand-green-300",
  gray: "border border-gray-500 bg-gray-500",
  white: "border border-white bg-white text-black bg-opacity-100",
};

const variantClasses = {
  transparent: "bg-transparent text-black border-transparent",
  primary: "text-white",
  outline: "bg-transparent border",
  secondary: "bg-opacity-50",
};

const Button: React.FC<ButtonProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      children,
      onClick,
      disabled = false,
      variant = "primary",
      colorScheme = "default",
      className = "",
      style = {},
      type = "button",
      icon,
      rounded = false,
    },
    ref,
  ) => {
    const schemeClasses = colorSchemes[colorScheme] || colorSchemes.default;
    const variantClass = variantClasses[variant] || variantClasses.primary;
    const disabledStyle = disabled
      ? "opacity-50 cursor-not-allowed"
      : "hover:bg-opacity-90 active:bg-opacity-80";

    // Combine base, color scheme, variant, and additional classes
    const buttonClassNames = clsx(
      baseClasses,
      schemeClasses,
      variantClass,
      disabledStyle,
      className,
      {
        "rounded-lg": !rounded,
        "rounded-full": rounded,
      },
    );

    return (
      <button
        ref={ref}
        className={`${buttonClassNames}`}
        onClick={onClick}
        disabled={disabled}
        type={type}
        style={style}
      >
        {children}
        {icon && <span className="ml-2 flex items-center">{icon}</span>}
      </button>
    );
  },
);

Button.displayName = "Button";

export const BASE_BUTTON_CLASSES = baseClasses;
export const BUTTON_COLOR_SCHEMES = colorSchemes;

export default Button;
