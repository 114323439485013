interface LocalStore {
  getItem: (name: string) => Promise<any>;
  setItem: (name: string, value: any) => Promise<void>;
  removeItem: (name: string) => Promise<void>;
}

const createLocalStore = (expirationInMinutes: number): LocalStore => ({
  getItem: async (name: string) => {
    const item = localStorage.getItem(name);
    if (!item) return null;

    const { value, expiration } = JSON.parse(item);
    if (expiration && new Date().getTime() > expiration) {
      localStorage.removeItem(name);
      return null;
    }
    return value;
  },
  setItem: async (name: string, value: any) => {
    const expiration = new Date().getTime() + expirationInMinutes * 60 * 1000;
    const item = JSON.stringify({ value, expiration });
    localStorage.setItem(name, item);
  },
  removeItem: async (name: string) => {
    localStorage.removeItem(name);
  },
});

export default createLocalStore;
