import { useLocale } from "next-intl";

import Typography from "../ui/Typography";

const Terms = () => {
  const locale = useLocale();

  if (locale === "fr") {
    return (
      <div className="max-w-3xl mx-auto p-5">
        <p className="mb-3">
          <Typography.Heading size="lg" className="mb-5">
            Canada’s Forest Trust Corporation
          </Typography.Heading>
        </p>
        <p className="mb-3">
          <Typography.Heading size="md" className="mb-3">
            “Portal” Terms and Conditions of Use
          </Typography.Heading>
        </p>
        <p className="mb-3">
          THESE TERMS TAKE EFFECT UPON ACCESS TO THE PORTAL. BY ACCESSING THE
          PORTAL YOU: (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE
          TERMS; (B)&nbsp;REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER,
          AND AUTHORITY TO ENTER INTO THESE TERMS; AND, IF YOU ARE ENTERING INTO
          THESE TERMS ON BEHALF OF AN ORGANIZATION, THAT YOU ARE DULY AUTHORIZED
          TO BIND THAT ORGANIZATION TO THESE TERMS; AND (C) ACCEPT THESE TERMS
          AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM.
        </p>
        <p className="mb-4">
          IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT OTHERWISE ACCESS OR
          USE THE PORTAL.
        </p>
        <ol className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Acceptance of Terms
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-4">
          These terms and conditions of use for the Canada’s Forest Trust
          Corporation Forest Portal (the “Portal”), constitute a legal agreement
          and are entered into by and between you and Canada’s Forest Trust
          Corporation (the “<strong>CFTC</strong>,” “<strong>we</strong>,” “
          <strong>us</strong>,” “<strong>our</strong>”). The following terms and
          conditions, together with our{" "}
          <a href="https://www.canadasforesttrust.ca/privacy-policy">
            Privacy Policy
          </a>{" "}
          (collectively, these “<strong>Terms</strong>”), govern your access to
          and use of the Portal.
        </p>
        <ol start={2} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Your Use of the Portal and Account Set-Up and Security
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          The security of your personal information is very important to us. We
          use physical, electronic, and administrative measures designed to
          secure your personal information from accidental loss and from
          unauthorized access, use, alteration, and disclosure.
        </p>
        <p className="mb-3">
          The safety and security of your information also depends on you. As a
          “<strong>User</strong>” of the Portal, you are responsible for your
          own access to and use of the Portal. You are required to ensure that
          anyone who accesses the Portal through your internet connection is
          aware of these Terms and complies with them.
        </p>
        <p className="mb-3">
          Certain content or areas on the Portal may require User registration.
          It is a condition of your use of the Portal that all the information
          you provide on the Portal is correct, current, and complete.
        </p>
        <p className="mb-3">
          Unfortunately, the transmission of information via the Internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Portal. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Portal.
        </p>
        <p className="mb-3">
          Your provision of contact information, registration information and
          any other submissions you make to the Portal through any functionality
          (collectively, “<strong>Interactive Functions</strong>”) constitutes
          your consent to all actions we take with respect to such information
          consistent with our{" "}
          <a href="https://www.canadasforesttrust.ca/privacy-policy">
            Privacy Policy
          </a>
          .
        </p>
        <p className="mb-3">
          Any username, password, or any other piece of information chosen by
          you, or provided to you as part of our security procedures, must be
          treated as confidential, and you must not disclose it to any other
          person or entity. You must exercise caution when accessing your
          account from a public or shared computer so that others are not able
          to view or record your password or other personal information. You
          understand and agree that should you be provided an account, your
          account is personal to you and you agree not to provide any other
          person with access to this Portal or portions of it using your
          username, password, or other security information. You agree to notify
          us immediately of any unauthorized access to or use of your username
          or password or any other breach of security. You also agree to ensure
          that you logout from your account at the end of each session. You are
          responsible for any password misuse or any unauthorized access.
        </p>
        <p className="mb-3">
          We reserve the right at any time and from time to time, to disable or
          terminate your account, any username, password, or other identifier,
          whether chosen by you or provided by us, in our sole discretion for
          any or no reason, including any violation of any provision of these
          Terms.
        </p>
        <p className="mb-3">
          You are prohibited from attempting to circumvent and from violating
          the security of this Portal, including, without limitation: (a)
          accessing content and data that is not intended for you; (b)
          attempting to breach or breaching the security and/or authentication
          measures which are not authorized; (c) restricting, disrupting or
          disabling service to users, hosts, servers, or networks; (d) illicitly
          reproducing TCP/IP packet header; (e) disrupting network services and
          otherwise disrupting Portal owner’s ability to monitor the Portal; (f)
          using any robot, spider, or other automatic device, process, or means
          to access the Portal for any purpose, including monitoring or copying
          any of the material on the Portal; (g) introducing any viruses, trojan
          horses, worms, logic bombs, or other material that is malicious or
          technologically harmful; (h) attacking the Portal via a
          denial-of-service attack, distributed denial-of-service attack,
          flooding, mailbombing, or crashing; and (i) otherwise attempting to
          interfere with the proper working of the Portal.
        </p>
        <ol start={3} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Intellectual Property Rights and Ownership
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          You understand and agree that the Portal and its entire contents,
          features, and functionality, including, but not limited to, all
          information, software, code, text, displays, graphics, photographs,
          video, audio, design, presentation, selection, and arrangement, are
          owned by the CFTC, its licensors, or other providers of such material
          and are protected in all forms by intellectual property laws including
          without limitation, copyright, trademark, patent, trade secret, and
          any other proprietary rights.
        </p>
        <p className="mb-3">
          The CFTC name, trademarks, logo, and all related names, logos, product
          and service names, designs, images, and slogans are trademarks of the
          CFTC or its affiliates or licensors. You must not use such marks
          without the prior written permission of the CFTC. Other names, logos,
          product and service names, designs, images, and slogans mentioned, or
          which appear on this Portal are the trademarks of their respective
          owners. Use of any such property, except as expressly authorized,
          shall constitute an infringement or violation of the rights of the
          property owner and may be a violation of federal or other laws and
          could subject the infringer to legal action.
        </p>
        <p className="mb-3">
          You may only use the Portal for your personal and non-commercial use.
          You shall not directly or indirectly reproduce, compile for an
          internal database, distribute, modify, create derivative works of,
          publicly display, publicly perform, republish, download, store, or
          transmit any of the material on our Portal, in any form or medium
          whatsoever except:
        </p>
        <ol>
          <li>
            <p className="mb-3">
              your computer and browser may temporarily store or cache copies of
              materials being accessed and viewed;
            </p>
          </li>
          <li>
            <p className="mb-3">
              a reasonable number of copies for personal use or educational use
              only may be printed keeping any proprietary notices thereon, which
              may only be used for non-commercial and lawful personal or
              educational use and not for further reproduction, publication, or
              distribution of any kind on any medium whatsoever;
            </p>
          </li>
          <li>
            <p className="mb-3">
              in the event social media platforms are linked to certain content
              on our Portal, you may take such actions as our Portal and such
              third-party social media platforms permit.
            </p>
          </li>
        </ol>
        <p className="mb-3">
          Users are not permitted to modify copies of any materials from this
          Portal nor delete or alter any copyright, trademark, or other
          proprietary rights notices from copies of materials from this site.
          You must not access or use for any commercial purposes any part of the
          Portal or any services or materials available through the Portal.
        </p>
        <p className="mb-3">
          If you print off, copy or download any part of our Portal in breach of
          these Terms, your right to use the Portal will cease immediately and
          you must, at our option, return or destroy any copies of the materials
          you have made. You have no right, title, or interest in or to the
          Portal or to any content on the Portal, and all rights not expressly
          granted are reserved by the CFTC. Any use of the Portal not expressly
          permitted by these Terms is a breach of these Terms and may infringe
          or violate copyright, trademark, and other intellectual property or
          other proprietary laws.
        </p>
        <ol start={4} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Greenhouse Gas Offset Credits
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          If the federal, provincial, and/or territorial governments release
          legislation that allows for the creation of regulatory greenhouse gas
          emission offsets resulting from this Agreement (&quot;
          <strong>Regulatory Offsets</strong>&quot;), You acknowledge and agree
          that you will not seek to create Regulatory Offsets if and to the
          extent that voluntary greenhouse gas emission offsets have already
          been created, used, advertised and/or retired.
        </p>
        <ol start={5} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Conditions of Use and Portal Content Standards
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          As a condition of your access and use, you agree that you may use the
          Portal only for lawful purposes and in accordance with these Terms.
        </p>
        <p className="mb-3">
          Without limiting the foregoing, you warrant and agree that your use of
          the Portal shall not:
        </p>
        <ol>
          <li>
            <p className="mb-3">
              In any manner violate any applicable federal, provincial, local,
              or international law or regulation including, without limitation,
              any laws regarding the export of data or software, patent,
              trademark, trade secret, copyright, or other intellectual
              property, legal rights (including the rights of publicity and
              privacy of others) or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              and our{" "}
              <a href="https://www.canadasforesttrust.ca/privacy-policy">
                Privacy Policy
              </a>
              .
            </p>
          </li>
          <li>
            <p className="mb-3">
              In any manner violate the terms of use of any third-party website
              that is linked to the Portal, including but not limited to, any
              third-party social media website.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Include or contain any material that is exploitive, obscene,
              harmful, threatening, abusive, harassing, hateful, defamatory,
              sexually explicit or pornographic, violent, inflammatory, or
              discriminatory based on race, sex, religion, nationality,
              disability, sexual orientation, or age or other such legally
              prohibited ground or be otherwise objectionable, such
              determination to be made in CFTC’s sole discretion.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Involve stalking, attempting to exploit or harm any individual
              (including minors) in any way by exposing them to inappropriate
              content or otherwise or ask for personal information as prohibited
              under applicable laws, regulations, or code.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Involve, provide, or contribute any false, inaccurate, or
              misleading information.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Include sending, knowingly receiving, uploading, downloading,
              using, or reusing any material that does not comply with these
              Terms.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Encourage any other conduct that restricts or inhibits anyone’s
              use or enjoyment of the Portal, or which, as determined by us, may
              harm the CFTC or users of the Portal or expose them to liability.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Cause annoyance, inconvenience, or needless anxiety or be likely
              to upset, embarrass, or alarm any other person.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Promote any illegal activity, or advocate, promote, or assist any
              unlawful act.
            </p>
          </li>
        </ol>
        <ol start={6} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Portal Monitoring and Enforcement, Suspension and Termination
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          CFTC has the right, without provision of notice to:
        </p>
        <ul>
          <li>
            <p className="mb-3">
              Take appropriate legal action, including, without limitation,
              referral to law enforcement or regulatory authority, or notifying
              the harmed party of any illegal or unauthorized use of the Portal.
              Without limiting the foregoing, we have the right to fully
              cooperate with any law enforcement authorities or court order
              requesting or directing us to disclose the identity or other
              information of anyone accessing or requesting any materials on or
              through the Portal.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Terminate or suspend your access to all or part of the Portal for
              any or no reason, including, without limitation, any violation of
              these Terms.
            </p>
          </li>
        </ul>
        <p className="mb-3">
          YOU WAIVE AND HOLD HARMLESS THE CFTC AND ITS PARENT, SUBSIDIARIES,
          AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
          AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES,
          SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY
          ACTION TAKEN BY CFTC AND ANY OF THE FOREGOING PARTIES RELATING TO ANY,
          INVESTIGATIONS BY EITHER CFTC OR SUCH PARTIES OR BY LAW ENFORCEMENT
          AUTHORITIES.
        </p>
        <p className="mb-3">
          We have no obligation, nor any responsibility to any party to monitor
          the Portal or its use.
        </p>
        <ol start={7}>
          <li>
            <Typography.Heading size="sm" className="mb-3">
              No Reliance
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          The content on our Portal is provided for general information purposes
          only and content relating to your Smart ForestTM may contain
          estimations that are subject to variation based on a number of
          environmental factors, including but not limited to: fire, drought,
          flooding, severe weather conditions, climate change, timber diseases
          and insect infestations.
        </p>
        <p className="mb-3">
          Although we make reasonable efforts to update the information on our
          Portal and validate the information relating to your Smart ForestTM,
          we make no representations, warranties, or guarantees, whether express
          or implied, that the content is accurate, complete, or up to date.
        </p>
        <p className="mb-3">
          This Portal may include content provided by third parties, including
          from other users and third-party licensors. All statements and/or
          opinions expressed in any such third-party content, other than the
          content provided by CFTC, are solely the opinions and the
          responsibility of the person or entity providing those materials. Such
          materials do not necessarily reflect the opinion of CFTC. CFTC has no
          responsibility or liability whatsoever to you, or any third party, for
          the content or accuracy of any third-party materials.
        </p>
        <ol start={8} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Privacy
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          By submitting your personal information and using our Portal, you
          consent to the collection, use, reproduction, hosting, transmission,
          and disclosure of any such user content submissions in compliance with
          our{" "}
          <a href="https://www.canadasforesttrust.ca/privacy-policy">
            Privacy Policy
          </a>
          , as we deem necessary for use of the Portal.
        </p>
        <p className="mb-3">
          By using this Portal you are consenting to the use of cookies which
          allow a server to recall previous requests or registration and/or IP
          addresses to analyze website use patterns. You can set your browser to
          notify you before you receive a cookie, giving you the chance to
          decide whether to accept it. You can also set your browser to turn off
          cookies. If you do, however, some areas of the Portal may not function
          adequately. For more information on this automated information
          gathering practices, see our{" "}
          <a href="https://www.canadasforesttrust.ca/privacy-policy">
            Privacy Policy
          </a>
          .
        </p>
        <ol start={9} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Third-Party Websites
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          For your convenience, this Portal may provide links or pointers to
          third-party sites. We make no representations about any other websites
          that may be accessed from this Portal. If you choose to access any
          such sites, you do so at your own risk. We have no control over the
          contents of any such third-party sites and accept no responsibility
          for such sites or for any loss or damage that may arise from your use
          of them. You are subject to any terms and conditions of such
          third-party sites.
        </p>
        <p className="mb-3">
          Such links to third-party sites from the Portal may include links to
          certain social media features that enable you to link or transmit on
          your own or using certain third-party websites, certain content from
          this Portal. You may only use these features when they are provided by
          us and solely with respect to the content identified.
        </p>
        <p className="mb-3">
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it.
          You must not establish a link in such a way as to suggest any form of
          association, approval, or endorsement on our part where none exists.
          Our Portal must not be framed on any other site, nor may you create a
          link to any part of our Portal other than the homepage. We reserve the
          right to withdraw linking permission without notice. The website in
          which you are linking must comply in all respects with the provisions
          regarding Conditions of Use and Site Content Standards in these Terms.
          You agree to cooperate with us in causing any unauthorized framing or
          linking to immediately stop.
        </p>
        <ol start={10} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Geographic Restrictions
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          The owner of the Portal is based in the Province of Ontario in Canada.
          We provide this Portal for use only by persons located in Canada. This
          Portal is not intended for use in any jurisdiction where its use is
          not permitted. If you access the Portal from outside Canada, you do so
          at your own risk and you are responsible for compliance with local
          laws of your jurisdiction.
        </p>
        <ol start={11}>
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Disclaimer of Warranties
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE PORTAL, ITS CONTENT, AND
          ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE PORTAL IS AT YOUR
          OWN RISK. THE PORTAL, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR
          ATTAINED THROUGH THE PORTAL ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND,
          EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT
          ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
          LAW.
        </p>
        <p className="mb-3">
          NEITHER CFTC NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR
          RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
          CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
          WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY,
          OR AVAILABILITY OF THE PORTAL OR ITS CONTENTS. WITHOUT LIMITING THE
          FOREGOING, NEITHER CFTC NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR
          THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
          PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
          REPRESENT OR WARRANT THAT THE PORTAL, ITS CONTENT, OR ANY SERVICES OR
          ITEMS FOUND OR ATTAINED THROUGH THE PORTAL WILL BE ACCURATE, RELIABLE,
          ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
          PORTAL OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS.
        </p>
        <p className="mb-3">
          WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
          FOR DOWNLOADING FROM THE INTERNET OR THE PORTAL WILL BE FREE OF
          VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY
          RESPONSIBLE FOR YOUR USE OF THE PORTAL AND YOUR COMPUTER, INTERNET,
          AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT
          BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK,
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING,
          MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS,
          OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE PORTAL OR ANY SERVICES OR ITEMS FOUND
          OR ATTAINED THROUGH THE PORTAL OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY PORTAL LINKED TO IT.
        </p>
        <ol start={12} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Limitation on Liability
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
          CIRCUMSTANCES WILL CFTC OR ITS PARENT, SUBSIDIARIES, AFFILIATES OR
          THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
          PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
          BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT
          MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY
          LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO,
          PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
          REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
          LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
          TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR
          OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO
          KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
          USE, OR RELIANCE ON, THE PORTAL, ANY LINKED PORTALS OR SUCH OTHER
          THIRD-PARTY PORTALS, NOR ANY PORTAL CONTENT, MATERIALS, POSTING, OR
          INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD
          REASON TO KNOW.
        </p>
        <ol start={13} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Indemnification
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          To the maximum extent permitted by applicable law, you agree to
          defend, indemnify, and hold harmless CFTC, its parent, subsidiaries,
          affiliates, and their respective directors, officers, employees,
          agents, service providers, contractors, licensors, suppliers,
          successors, and assigns from and against any claims, liabilities,
          damages, judgments, awards, losses, costs, expenses, or fees
          (including reasonable legal fees and disbursements) arising out of or
          relating to your breach of these Terms or your use of the Portal,
          including, but not limited to third-party sites, any use of the
          Portal’s content, services, and products other than as expressly
          authorized in these Terms.
        </p>
        <ol start={14} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Governing Law and Choice of Forum
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          The Portal and these Terms will be governed by and construed in
          accordance with the laws of the Province of Ontario and the federal
          laws of Canada applicable therein, without giving effect to any choice
          or conflict of law provision, principle, or rule (whether of the laws
          of the Province of Ontario or any other jurisdiction) and
          notwithstanding your domicile, residence, or physical location.
        </p>
        <p className="mb-3">
          Any action or proceeding arising out of or relating to this Portal and
          under these Terms will be instituted in the courts of the Province of
          Ontario and/or the Federal Court of Canada, and each party irrevocably
          submits to the exclusive jurisdiction of such courts in any such
          action or proceeding. You waive any and all objections to the exercise
          of jurisdiction over you by such courts and to the venue of such
          courts.
        </p>
        <ol start={15} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Waiver
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          No failure to exercise, or delay in exercising, any right, remedy,
          power, or privilege arising from these Terms operates, or may be
          construed, as a waiver thereof. No single or partial exercise of any
          right, remedy, power, or privilege hereunder precludes any other or
          further exercise thereof or the exercise of any other right, remedy,
          power, or privilege.
        </p>
        <ol start={16} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Severability
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          If any term or provision of these Terms is invalid, illegal, or
          unenforceable in any jurisdiction, such invalidity, illegality, or
          unenforceability shall not affect any other term or provision of these
          Terms or invalidate or render unenforceable such term or provision in
          any other jurisdiction.
        </p>
        <ol start={17} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Entire Agreement
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          The Terms and our Privacy Policy constitute the sole and entire
          agreement between you and CFTC regarding the Portal and supersedes all
          prior and contemporaneous understandings, agreements, representations
          and warranties, both written and oral, regarding such subject matter.
        </p>
        <ol start={18} className="list-disk">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Reporting and Contact
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Please send any reports of potential misuse, or any requests or
          comments relating to the Portal, to us at the following email address:{" "}
          <a href="mailto:admin@canadasforesttrust.ca">
            <strong>admin@canadasforesttrust.ca</strong>
          </a>
          .
        </p>
      </div>
    );
  }
  return (
    <div className="max-w-3xl mx-auto p-5">
      <p className="mb-3">
        <Typography.Heading size="lg" className="mb-5">
          Canada’s Forest Trust Corporation
        </Typography.Heading>
      </p>
      <p className="mb-3">
        <Typography.Heading size="md" className="mb-3">
          “Portal” Terms and Conditions of Use
        </Typography.Heading>
      </p>
      <p className="mb-3">
        THESE TERMS TAKE EFFECT UPON ACCESS TO THE PORTAL. BY ACCESSING THE
        PORTAL YOU: (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE
        TERMS; (B)&nbsp;REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER,
        AND AUTHORITY TO ENTER INTO THESE TERMS; AND, IF YOU ARE ENTERING INTO
        THESE TERMS ON BEHALF OF AN ORGANIZATION, THAT YOU ARE DULY AUTHORIZED
        TO BIND THAT ORGANIZATION TO THESE TERMS; AND (C) ACCEPT THESE TERMS AND
        AGREE THAT YOU ARE LEGALLY BOUND BY THEM.
      </p>
      <p className="mb-4">
        IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT OTHERWISE ACCESS OR
        USE THE PORTAL.
      </p>
      <ol className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Acceptance of Terms
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-4">
        These terms and conditions of use for the Canada’s Forest Trust
        Corporation Forest Portal (the “Portal”), constitute a legal agreement
        and are entered into by and between you and Canada’s Forest Trust
        Corporation (the “<strong>CFTC</strong>,” “<strong>we</strong>,” “
        <strong>us</strong>,” “<strong>our</strong>”). The following terms and
        conditions, together with our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>{" "}
        (collectively, these “<strong>Terms</strong>”), govern your access to
        and use of the Portal.
      </p>
      <ol start={2} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Your Use of the Portal and Account Set-Up and Security
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The security of your personal information is very important to us. We
        use physical, electronic, and administrative measures designed to secure
        your personal information from accidental loss and from unauthorized
        access, use, alteration, and disclosure.
      </p>
      <p className="mb-3">
        The safety and security of your information also depends on you. As a “
        <strong>User</strong>” of the Portal, you are responsible for your own
        access to and use of the Portal. You are required to ensure that anyone
        who accesses the Portal through your internet connection is aware of
        these Terms and complies with them.
      </p>
      <p className="mb-3">
        Certain content or areas on the Portal may require User registration. It
        is a condition of your use of the Portal that all the information you
        provide on the Portal is correct, current, and complete.
      </p>
      <p className="mb-3">
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Portal. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Portal.
      </p>
      <p className="mb-3">
        Your provision of contact information, registration information and any
        other submissions you make to the Portal through any functionality
        (collectively, “<strong>Interactive Functions</strong>”) constitutes
        your consent to all actions we take with respect to such information
        consistent with our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <p className="mb-3">
        Any username, password, or any other piece of information chosen by you,
        or provided to you as part of our security procedures, must be treated
        as confidential, and you must not disclose it to any other person or
        entity. You must exercise caution when accessing your account from a
        public or shared computer so that others are not able to view or record
        your password or other personal information. You understand and agree
        that should you be provided an account, your account is personal to you
        and you agree not to provide any other person with access to this Portal
        or portions of it using your username, password, or other security
        information. You agree to notify us immediately of any unauthorized
        access to or use of your username or password or any other breach of
        security. You also agree to ensure that you logout from your account at
        the end of each session. You are responsible for any password misuse or
        any unauthorized access.
      </p>
      <p className="mb-3">
        We reserve the right at any time and from time to time, to disable or
        terminate your account, any username, password, or other identifier,
        whether chosen by you or provided by us, in our sole discretion for any
        or no reason, including any violation of any provision of these Terms.
      </p>
      <p className="mb-3">
        You are prohibited from attempting to circumvent and from violating the
        security of this Portal, including, without limitation: (a) accessing
        content and data that is not intended for you; (b) attempting to breach
        or breaching the security and/or authentication measures which are not
        authorized; (c) restricting, disrupting or disabling service to users,
        hosts, servers, or networks; (d) illicitly reproducing TCP/IP packet
        header; (e) disrupting network services and otherwise disrupting Portal
        owner’s ability to monitor the Portal; (f) using any robot, spider, or
        other automatic device, process, or means to access the Portal for any
        purpose, including monitoring or copying any of the material on the
        Portal; (g) introducing any viruses, trojan horses, worms, logic bombs,
        or other material that is malicious or technologically harmful; (h)
        attacking the Portal via a denial-of-service attack, distributed
        denial-of-service attack, flooding, mailbombing, or crashing; and (i)
        otherwise attempting to interfere with the proper working of the Portal.
      </p>
      <ol start={3} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Intellectual Property Rights and Ownership
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        You understand and agree that the Portal and its entire contents,
        features, and functionality, including, but not limited to, all
        information, software, code, text, displays, graphics, photographs,
        video, audio, design, presentation, selection, and arrangement, are
        owned by the CFTC, its licensors, or other providers of such material
        and are protected in all forms by intellectual property laws including
        without limitation, copyright, trademark, patent, trade secret, and any
        other proprietary rights.
      </p>
      <p className="mb-3">
        The CFTC name, trademarks, logo, and all related names, logos, product
        and service names, designs, images, and slogans are trademarks of the
        CFTC or its affiliates or licensors. You must not use such marks without
        the prior written permission of the CFTC. Other names, logos, product
        and service names, designs, images, and slogans mentioned, or which
        appear on this Portal are the trademarks of their respective owners. Use
        of any such property, except as expressly authorized, shall constitute
        an infringement or violation of the rights of the property owner and may
        be a violation of federal or other laws and could subject the infringer
        to legal action.
      </p>
      <p className="mb-3">
        You may only use the Portal for your personal and non-commercial use.
        You shall not directly or indirectly reproduce, compile for an internal
        database, distribute, modify, create derivative works of, publicly
        display, publicly perform, republish, download, store, or transmit any
        of the material on our Portal, in any form or medium whatsoever except:
      </p>
      <ol>
        <li>
          <p className="mb-3">
            your computer and browser may temporarily store or cache copies of
            materials being accessed and viewed;
          </p>
        </li>
        <li>
          <p className="mb-3">
            a reasonable number of copies for personal use or educational use
            only may be printed keeping any proprietary notices thereon, which
            may only be used for non-commercial and lawful personal or
            educational use and not for further reproduction, publication, or
            distribution of any kind on any medium whatsoever;
          </p>
        </li>
        <li>
          <p className="mb-3">
            in the event social media platforms are linked to certain content on
            our Portal, you may take such actions as our Portal and such
            third-party social media platforms permit.
          </p>
        </li>
      </ol>
      <p className="mb-3">
        Users are not permitted to modify copies of any materials from this
        Portal nor delete or alter any copyright, trademark, or other
        proprietary rights notices from copies of materials from this site. You
        must not access or use for any commercial purposes any part of the
        Portal or any services or materials available through the Portal.
      </p>
      <p className="mb-3">
        If you print off, copy or download any part of our Portal in breach of
        these Terms, your right to use the Portal will cease immediately and you
        must, at our option, return or destroy any copies of the materials you
        have made. You have no right, title, or interest in or to the Portal or
        to any content on the Portal, and all rights not expressly granted are
        reserved by the CFTC. Any use of the Portal not expressly permitted by
        these Terms is a breach of these Terms and may infringe or violate
        copyright, trademark, and other intellectual property or other
        proprietary laws.
      </p>
      <ol start={4} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Greenhouse Gas Offset Credits
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        If the federal, provincial, and/or territorial governments release
        legislation that allows for the creation of regulatory greenhouse gas
        emission offsets resulting from this Agreement (&quot;
        <strong>Regulatory Offsets</strong>&quot;), You acknowledge and agree
        that you will not seek to create Regulatory Offsets if and to the extent
        that voluntary greenhouse gas emission offsets have already been
        created, used, advertised and/or retired.
      </p>
      <ol start={5} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Conditions of Use and Portal Content Standards
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        As a condition of your access and use, you agree that you may use the
        Portal only for lawful purposes and in accordance with these Terms.
      </p>
      <p className="mb-3">
        Without limiting the foregoing, you warrant and agree that your use of
        the Portal shall not:
      </p>
      <ol>
        <li>
          <p className="mb-3">
            In any manner violate any applicable federal, provincial, local, or
            international law or regulation including, without limitation, any
            laws regarding the export of data or software, patent, trademark,
            trade secret, copyright, or other intellectual property, legal
            rights (including the rights of publicity and privacy of others) or
            contain any material that could give rise to any civil or criminal
            liability under applicable laws or regulations or that otherwise may
            be in conflict with these Terms and our{" "}
            <a href="https://www.canadasforesttrust.ca/privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
        </li>
        <li>
          <p className="mb-3">
            In any manner violate the terms of use of any third-party website
            that is linked to the Portal, including but not limited to, any
            third-party social media website.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Include or contain any material that is exploitive, obscene,
            harmful, threatening, abusive, harassing, hateful, defamatory,
            sexually explicit or pornographic, violent, inflammatory, or
            discriminatory based on race, sex, religion, nationality,
            disability, sexual orientation, or age or other such legally
            prohibited ground or be otherwise objectionable, such determination
            to be made in CFTC’s sole discretion.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Involve stalking, attempting to exploit or harm any individual
            (including minors) in any way by exposing them to inappropriate
            content or otherwise or ask for personal information as prohibited
            under applicable laws, regulations, or code.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Involve, provide, or contribute any false, inaccurate, or misleading
            information.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Include sending, knowingly receiving, uploading, downloading, using,
            or reusing any material that does not comply with these Terms.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Encourage any other conduct that restricts or inhibits anyone’s use
            or enjoyment of the Portal, or which, as determined by us, may harm
            the CFTC or users of the Portal or expose them to liability.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, or alarm any other person.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Promote any illegal activity, or advocate, promote, or assist any
            unlawful act.
          </p>
        </li>
      </ol>
      <ol start={6} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Portal Monitoring and Enforcement, Suspension and Termination
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        CFTC has the right, without provision of notice to:
      </p>
      <ul>
        <li>
          <p className="mb-3">
            Take appropriate legal action, including, without limitation,
            referral to law enforcement or regulatory authority, or notifying
            the harmed party of any illegal or unauthorized use of the Portal.
            Without limiting the foregoing, we have the right to fully cooperate
            with any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone
            accessing or requesting any materials on or through the Portal.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Terminate or suspend your access to all or part of the Portal for
            any or no reason, including, without limitation, any violation of
            these Terms.
          </p>
        </li>
      </ul>
      <p className="mb-3">
        YOU WAIVE AND HOLD HARMLESS THE CFTC AND ITS PARENT, SUBSIDIARIES,
        AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
        SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND
        SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY
        CFTC AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY
        EITHER CFTC OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.
      </p>
      <p className="mb-3">
        We have no obligation, nor any responsibility to any party to monitor
        the Portal or its use.
      </p>
      <ol start={7}>
        <li>
          <Typography.Heading size="sm" className="mb-3">
            No Reliance
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The content on our Portal is provided for general information purposes
        only and content relating to your Smart ForestTM may contain estimations
        that are subject to variation based on a number of environmental
        factors, including but not limited to: fire, drought, flooding, severe
        weather conditions, climate change, timber diseases and insect
        infestations.
      </p>
      <p className="mb-3">
        Although we make reasonable efforts to update the information on our
        Portal and validate the information relating to your Smart ForestTM, we
        make no representations, warranties, or guarantees, whether express or
        implied, that the content is accurate, complete, or up to date.
      </p>
      <p className="mb-3">
        This Portal may include content provided by third parties, including
        from other users and third-party licensors. All statements and/or
        opinions expressed in any such third-party content, other than the
        content provided by CFTC, are solely the opinions and the responsibility
        of the person or entity providing those materials. Such materials do not
        necessarily reflect the opinion of CFTC. CFTC has no responsibility or
        liability whatsoever to you, or any third party, for the content or
        accuracy of any third-party materials.
      </p>
      <ol start={8} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Privacy
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        By submitting your personal information and using our Portal, you
        consent to the collection, use, reproduction, hosting, transmission, and
        disclosure of any such user content submissions in compliance with our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>
        , as we deem necessary for use of the Portal.
      </p>
      <p className="mb-3">
        By using this Portal you are consenting to the use of cookies which
        allow a server to recall previous requests or registration and/or IP
        addresses to analyze website use patterns. You can set your browser to
        notify you before you receive a cookie, giving you the chance to decide
        whether to accept it. You can also set your browser to turn off cookies.
        If you do, however, some areas of the Portal may not function
        adequately. For more information on this automated information gathering
        practices, see our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <ol start={9} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Third-Party Websites
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        For your convenience, this Portal may provide links or pointers to
        third-party sites. We make no representations about any other websites
        that may be accessed from this Portal. If you choose to access any such
        sites, you do so at your own risk. We have no control over the contents
        of any such third-party sites and accept no responsibility for such
        sites or for any loss or damage that may arise from your use of them.
        You are subject to any terms and conditions of such third-party sites.
      </p>
      <p className="mb-3">
        Such links to third-party sites from the Portal may include links to
        certain social media features that enable you to link or transmit on
        your own or using certain third-party websites, certain content from
        this Portal. You may only use these features when they are provided by
        us and solely with respect to the content identified.
      </p>
      <p className="mb-3">
        You may link to our homepage, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
        You must not establish a link in such a way as to suggest any form of
        association, approval, or endorsement on our part where none exists. Our
        Portal must not be framed on any other site, nor may you create a link
        to any part of our Portal other than the homepage. We reserve the right
        to withdraw linking permission without notice. The website in which you
        are linking must comply in all respects with the provisions regarding
        Conditions of Use and Site Content Standards in these Terms. You agree
        to cooperate with us in causing any unauthorized framing or linking to
        immediately stop.
      </p>
      <ol start={10} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Geographic Restrictions
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The owner of the Portal is based in the Province of Ontario in Canada.
        We provide this Portal for use only by persons located in Canada. This
        Portal is not intended for use in any jurisdiction where its use is not
        permitted. If you access the Portal from outside Canada, you do so at
        your own risk and you are responsible for compliance with local laws of
        your jurisdiction.
      </p>
      <ol start={11}>
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Disclaimer of Warranties
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE PORTAL, ITS CONTENT, AND
        ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE PORTAL IS AT YOUR
        OWN RISK. THE PORTAL, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR
        ATTAINED THROUGH THE PORTAL ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND,
        EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT
        ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </p>
      <p className="mb-3">
        NEITHER CFTC NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR
        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
        CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
        WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE
        COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR
        AVAILABILITY OF THE PORTAL OR ITS CONTENTS. WITHOUT LIMITING THE
        FOREGOING, NEITHER CFTC NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR
        THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
        PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
        REPRESENT OR WARRANT THAT THE PORTAL, ITS CONTENT, OR ANY SERVICES OR
        ITEMS FOUND OR ATTAINED THROUGH THE PORTAL WILL BE ACCURATE, RELIABLE,
        ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
        PORTAL OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
        OTHER HARMFUL COMPONENTS.
      </p>
      <p className="mb-3">
        WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
        FOR DOWNLOADING FROM THE INTERNET OR THE PORTAL WILL BE FREE OF VIRUSES
        OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR
        YOUR USE OF THE PORTAL AND YOUR COMPUTER, INTERNET, AND DATA SECURITY.
        TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
        LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED
        DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR
        CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER
        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
        YOUR USE OF THE PORTAL OR ANY SERVICES OR ITEMS FOUND OR ATTAINED
        THROUGH THE PORTAL OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT,
        OR ON ANY PORTAL LINKED TO IT.
      </p>
      <ol start={12} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Limitation on Liability
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
        CIRCUMSTANCES WILL CFTC OR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR
        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
        CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE
        FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION,
        FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
        PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
        AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
        LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
        LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
        OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS
        ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION
        WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE PORTAL, ANY
        LINKED PORTALS OR SUCH OTHER THIRD-PARTY PORTALS, NOR ANY PORTAL
        CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON EVEN IF THE PARTY
        WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
      </p>
      <ol start={13} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Indemnification
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        To the maximum extent permitted by applicable law, you agree to defend,
        indemnify, and hold harmless CFTC, its parent, subsidiaries, affiliates,
        and their respective directors, officers, employees, agents, service
        providers, contractors, licensors, suppliers, successors, and assigns
        from and against any claims, liabilities, damages, judgments, awards,
        losses, costs, expenses, or fees (including reasonable legal fees and
        disbursements) arising out of or relating to your breach of these Terms
        or your use of the Portal, including, but not limited to third-party
        sites, any use of the Portal’s content, services, and products other
        than as expressly authorized in these Terms.
      </p>
      <ol start={14} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Governing Law and Choice of Forum
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The Portal and these Terms will be governed by and construed in
        accordance with the laws of the Province of Ontario and the federal laws
        of Canada applicable therein, without giving effect to any choice or
        conflict of law provision, principle, or rule (whether of the laws of
        the Province of Ontario or any other jurisdiction) and notwithstanding
        your domicile, residence, or physical location.
      </p>
      <p className="mb-3">
        Any action or proceeding arising out of or relating to this Portal and
        under these Terms will be instituted in the courts of the Province of
        Ontario and/or the Federal Court of Canada, and each party irrevocably
        submits to the exclusive jurisdiction of such courts in any such action
        or proceeding. You waive any and all objections to the exercise of
        jurisdiction over you by such courts and to the venue of such courts.
      </p>
      <ol start={15} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Waiver
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        No failure to exercise, or delay in exercising, any right, remedy,
        power, or privilege arising from these Terms operates, or may be
        construed, as a waiver thereof. No single or partial exercise of any
        right, remedy, power, or privilege hereunder precludes any other or
        further exercise thereof or the exercise of any other right, remedy,
        power, or privilege.
      </p>
      <ol start={16} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Severability
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        If any term or provision of these Terms is invalid, illegal, or
        unenforceable in any jurisdiction, such invalidity, illegality, or
        unenforceability shall not affect any other term or provision of these
        Terms or invalidate or render unenforceable such term or provision in
        any other jurisdiction.
      </p>
      <ol start={17} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Entire Agreement
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The Terms and our Privacy Policy constitute the sole and entire
        agreement between you and CFTC regarding the Portal and supersedes all
        prior and contemporaneous understandings, agreements, representations
        and warranties, both written and oral, regarding such subject matter.
      </p>
      <ol start={18} className="list-disk">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Reporting and Contact
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        Please send any reports of potential misuse, or any requests or comments
        relating to the Portal, to us at the following email address:{" "}
        <a href="mailto:admin@canadasforesttrust.ca">
          <strong>admin@canadasforesttrust.ca</strong>
        </a>
        .
      </p>
    </div>
  );
};

export default Terms;
