import { useMutation } from "react-query";

import { apiAuthSession } from "../utils/api";

export const useAuthRequest = () => {
  return useMutation(
    async (email: string) => {
      const response = await apiAuthSession.post<{
        status: "OK" | "ERROR";
        message: string;
      }>("/auth/request", { email });
      return response.data;
    },
    {
      onSuccess: (data, variables, context) => {
        if (data.status === "OK") {
          const timestamp = Date.now();
          sessionStorage.setItem("emailForVerification", variables);
          sessionStorage.setItem("codeRequestTimestamp", timestamp.toString());
        } else {
          sessionStorage.removeItem("emailForVerification");
          sessionStorage.removeItem("codeRequestTimestamp");
          throw new Error(data.message);
        }
      },
      onError: (error, _variables, context) => {
        sessionStorage.removeItem("emailForVerification");
        sessionStorage.removeItem("codeRequestTimestamp");
      },
    },
  );
};

export const useAuthVerify = () => {
  return useMutation(
    async ({ email, code }: { email: string; code: string }) => {
      const response = await apiAuthSession.post<{
        status: "OK" | "ERROR";
        message: string;
      }>("/auth/verify", { email, code });
      if (response.data.status === "ERROR") {
        throw new Error(response.data.message);
      }
      return response.data;
    },
    {
      onSuccess: (data, _variables, _context) => {
        console.debug("OTP code verification successful:", data);
        if (data.status === "OK") {
          // Handle successful verification
        } else {
          throw new Error(data.message);
        }
      },
      onError: (error) => {
        console.error("Error verifying OTP code:", error);
      },
    },
  );
};

export const useAuthLogout = () => {
  return useMutation(async () => {
    const response = await apiAuthSession.post("/auth/logout");
    sessionStorage.removeItem("emailForVerification");
    sessionStorage.removeItem("codeRequestTimestamp");
    return response.data;
  });
};
