import { StateCreator } from "zustand";
import { PersistOptions } from "zustand/middleware";

import { AppConfig } from "../config/app.config";
import { createConditionalPersist } from "../store/helpers";
import createLocalStore from "../store/localStore";
import { User } from "../types/user.type";

export type UserState = {
  user: User | null;
  isAuthenticated: boolean;
  hydrated: boolean;
  setUser: (user: User | null) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setHydrated: (hydrated: boolean) => void;
};

const storage = createLocalStore(AppConfig.localStorageCacheTimeoutMin);

const createUserSlice: StateCreator<UserState> = (set) => ({
  user: null,
  isAuthenticated: false,
  hydrated: false,
  setUser: (user: User | null) => set({ user }),
  setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated }),
  setHydrated: (hydrated: boolean) => set({ hydrated }),
});

const userPersistOptions: PersistOptions<UserState> = {
  name: "user-storage",
  storage,
  onRehydrateStorage: () => (state) => {
    state?.setHydrated(true);
  },
};

export const createUserStore = createConditionalPersist(
  createUserSlice,
  userPersistOptions,
);

export const useUserStore = createUserStore(AppConfig.useLocalStorage);

export const clearUserStore = () => {
  useUserStore?.persist?.clearStorage();
};
