import { create, StateCreator, StoreApi, UseBoundStore } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

type PersistStore<T> = {
  persist: {
    clearStorage: () => void;
    rehydrate: () => void;
    hasHydrated: () => boolean;
    onHydrate: (fn: (state: T) => void) => () => void;
    onFinishHydration: (fn: (state: T) => void) => () => void;
  };
};

export function createConditionalPersist<T extends object>(
  config: StateCreator<T, [], []>,
  options: PersistOptions<T>,
): (shouldPersist: boolean) => UseBoundStore<StoreApi<T>> & PersistStore<T> {
  return (shouldPersist: boolean) => {
    if (shouldPersist) {
      const persistedConfig = persist(config, options);
      return create(persistedConfig) as UseBoundStore<StoreApi<T>> &
        PersistStore<T>;
    }
    return create(config) as UseBoundStore<StoreApi<T>> & PersistStore<T>;
  };
}
