import { StateCreator } from "zustand";
import { PersistOptions } from "zustand/middleware";

import { AppConfig } from "../config/app.config";
import { createConditionalPersist } from "../store/helpers";
import createLocalStore from "../store/localStore";
import { UserForest } from "../types/user.type";

export type ForestState = {
  forest: UserForest | null;
  setForest: (forest: UserForest | null) => void;
  forestCode: string | null;
  setForestCode: (code: string | null) => void;
};

const storage = createLocalStore(AppConfig.forestLocalStorageTimeMs);

const createForestSlice: StateCreator<ForestState> = (set) => ({
  forest: null,
  setForest: (forest) => set({ forest }),
  forestCode: null,
  setForestCode: (forestCode) => set({ forestCode }),
});

const forestPersistOptions: PersistOptions<ForestState> = {
  name: "forest-storage",
  storage,
};

const createForestStore = createConditionalPersist(
  createForestSlice,
  forestPersistOptions,
);

export const useForestStore = createForestStore(true);

export const clearForestStore = () => {
  useForestStore?.persist?.clearStorage();
};
