import { forwardRef, useEffect } from "react";
import { type Props, ReactSVG } from "react-svg";

export type IconProps = Props & {
  alt: string;
  className?: string;
  onLoad?: () => void;
};

const Icon = forwardRef<ReactSVG, IconProps>(
  (
    {
      src,
      color,
      width = "auto",
      height = "auto",
      alt,
      className,
      onLoad = () => {},
      ...rest
    },
    ref,
  ) => {
    useEffect(() => {
      const img = new Image();
      img.src = src;
      img.onload = onLoad;
    }, [src, onLoad]);

    return (
      <div className={className} style={{ color, width, height }} title={alt}>
        <ReactSVG
          ref={ref as any}
          src={src}
          width={width}
          height={height}
          color={color}
          {...rest}
        />
      </div>
    );
  },
);

Icon.displayName = "Icon";

export default Icon;
