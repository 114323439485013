import { useEffect } from "react";

import { useAuth } from "../contexts/AuthContext";
import { useSelectForest } from "./useForestQuery";
import { useForestStore } from "./useForestStore";

export const useForestSelection = () => {
  const { isLoading: isMutating } = useSelectForest();
  const { user } = useAuth();

  const { forest, setForestCode, setForest } = useForestStore();

  useEffect(() => {
    if (!forest && user?.forests.length) {
      setForest(user.forests[0]);
    }
  }, [forest, setForest, user]);

  return {
    isMutating,
    setForest,
    setForestCode,
  };
};
