import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";

import { AppConfig } from "../config/app.config";
import { type User, type UserForest } from "../types/user.type";
import { authenticatedApi } from "../utils/api";
import { useUserStore } from "./useUserStore";

export const useUser = (): UseQueryResult<User, Error> & {
  user: User | null;
  isAuthenticated: boolean;
  setUser: (user: User | null) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setAcceptTermsConditions: (acceptTermsConditions: boolean) => void;
  activeForests: UserForest[];
} => {
  const { user, isAuthenticated, setUser, setIsAuthenticated, hydrated } =
    useUserStore();

  const query = useQuery<User, Error>(
    "user",
    async () => {
      const response = await authenticatedApi.get<{ data: User }>("/user/me");
      return response.data.data;
    },
    {
      enabled: hydrated && !user && isAuthenticated,
      onSuccess: (data) => {
        setUser(data);
        setIsAuthenticated(true);
      },
      onError: () => {
        setUser(null);
        setIsAuthenticated(false);
      },
      staleTime: AppConfig.queryStaleTimeMs,
      cacheTime: AppConfig.queryCacheTimeMs,
    },
  );

  return {
    ...query,
    user,
    isAuthenticated,
    setUser,
    setIsAuthenticated,
    activeForests:
      user?.forests?.filter(
        (forest) =>
          !forest.membership ||
          forest?.membership?.stage?.toLowerCase() === "active",
      ) ?? [],
    setAcceptTermsConditions: (acceptTermsConditions: boolean) => {
      if (user) {
        setUser({
          ...user,
          hasReadTermsConditions: acceptTermsConditions,
        });
      }
    },
  };
};

export const useAcceptTermsConditions = (): UseMutationResult => {
  const { user, setUser } = useUserStore();

  return useMutation(
    async () => {
      if (!user) {
        throw new Error("User not found");
      }

      const response = await authenticatedApi.put("/user/accept-terms", {
        contactId: user.id,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        if (user) {
          setUser({
            ...user,
            hasReadTermsConditions: true,
          });
        }
      },
      onError: () => {
        if (user) {
          setUser({
            ...user,
            hasReadTermsConditions: false,
          });
        }
      },
    },
  );
};
