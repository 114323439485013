import { StateCreator } from "zustand";
import { PersistOptions } from "zustand/middleware";

import { AppConfig } from "../config/app.config";
import { createConditionalPersist } from "../store/helpers";
import createLocalStore from "../store/localStore";
import { DashboardData, DashboardProject } from "../types/dashboard.type";

export type DashboardState<T> = {
  hydrated: boolean;
  dashboards: Record<string, T>;
  setDashboard: (key: string, dashboard: T) => void;
  getDashboard: (key: string) => T | null;
  setHydrated: (hydrated: boolean) => void;
};

const storage = createLocalStore(AppConfig.localStorageCacheTimeoutMin);

function createDashboardStore<T>(storageName: string) {
  const createDashboardSlice: StateCreator<DashboardState<T>> = (set, get) => ({
    dashboards: {},
    hydrated: false,
    setDashboard: (key, dashboard) =>
      set((state) => ({
        dashboards: { ...state.dashboards, [key]: dashboard },
      })),
    getDashboard: (key) => get().dashboards[key] || null,
    setHydrated: (hydrated: boolean) => set({ hydrated }),
  });

  const dashboardPersistOptions: PersistOptions<DashboardState<T>> = {
    name: storageName,
    storage,
    onRehydrateStorage: () => (state) => {
      state?.setHydrated(true);
    },
  };

  const createStore = createConditionalPersist(
    createDashboardSlice,
    dashboardPersistOptions,
  );

  return createStore(AppConfig.useLocalStorage);
}

export const useProjectDashboardStore = createDashboardStore<DashboardProject>(
  "project-dashboard-storage",
);
export const useForestDashboardStore = createDashboardStore<DashboardData>(
  "forest-dashboard-storage",
);

export const clearProjectDashboardStorage = () => {
  useProjectDashboardStore?.persist?.clearStorage();
};

export const clearForestDashboardStorage = () => {
  useForestDashboardStore?.persist?.clearStorage();
};
